import React, { useState } from "react";
import {
  Box, Grid, Stack, Typography, useMediaQuery,
} from "@mui/material";
import styled from "@emotion/styled";
import { Trans } from "@lingui/macro";
import ArrowButton from "./ArrowButton";
import ArrowIcon from "./ArrowIcon";
import ArticleCard from "./ArticleCard";

const MOBILE_SIZE = 1;

function Carousel({
  items,
  text = undefined,
  title = undefined,
  max = 3,
  textButton = <Trans>View all</Trans>,
  path = undefined,
  ...rest
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const currentMax = isMobile ? MOBILE_SIZE : max;

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = items.length - 1;
    } else if (newIndex >= items.length) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  return (
    <Grid container spacing={2} {...rest}>
      <Grid item xs={12} md={3}>
        <Box>
          <StyledTypography mb={1} variant="h3" lineHeight="40px">{title}</StyledTypography>
          <StyledTypography mb={2} variant="small">{text}</StyledTypography>
          <Stack mb={2} spacing={2} direction="row" sx={{ cursor: "pointer", mt: { xs: 2, md: 10 } }}>
            <ArrowIcon
              direction="left"
              onClick={() => updateIndex(activeIndex - currentMax)}
            />
            <ArrowIcon onClick={() => updateIndex(activeIndex + currentMax)} />
          </Stack>
          {!isMobile && <ArrowButton path={path} title={textButton} />}
        </Box>
      </Grid>

      <Grid item xs={12} md={9}>
        <StyledCarousel>
          <StyledInner activeIndex={activeIndex} max={currentMax}>
            {items.map(({ attributes }) => (
              <Box minWidth={`${isMobile ? 100 : 30}% !important`}>
                <ArticleCard attributes={attributes} isDark />
              </Box>
            ))}
          </StyledInner>
        </StyledCarousel>
      </Grid>
      {isMobile && (
        <Grid item xs={12}>
          <ArrowButton path={path} title={textButton} />
        </Grid>
      )}
    </Grid>
  );
}

const StyledCarousel = styled.div`
  overflow: hidden;
`;

const StyledInner = styled.div`
  transform: translate(
    -${({ activeIndex, max }) => (activeIndex / max) * `${max === MOBILE_SIZE ? 100 : 105}`}%
  );
  transition: transform 0.3s;
  display: flex;
  gap: ${({ max }) => `${max !== MOBILE_SIZE && 5}%`};
`;

const StyledTypography = styled(Typography)`
  color: white;
`;

export default Carousel;
