import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Divider, Grid } from "@mui/material";
import { useLocalization } from "gatsby-theme-i18n";
import home_card_1_image from "../images/home-card-1.png";
import home_card_2_image from "../images/home-card-2.png";
import home_card_3_image from "../images/home-card-3.png";
import home_card_4_image from "../images/home-card-4.png";
import Container from "../components/Container";
import NewLayout from "../components/NewLayout";
import Carousel from "../components/Carousel";
import ImageCard from "../components/ImageCard";
import Hero from "../components/Hero";
import blog from "../cms/blog/blog";
import homeHero from "../cms/homeHero/homeHero";
import QuarterlyFigures from "../components/QuarterlyFigures";

const contentImages = () => [
  {
    kind: <Trans>For Fund Houses</Trans>,
    title: (
      <Trans>
        Access the largest distributor network and deploy your Go To Market
        swiftly
      </Trans>
    ),
    // description: (
    //   <Trans>
    //     Access the largest distributor network and deploy your Go To Market
    //     strategy in 24 hours
    //   </Trans>
    // ),
    image: home_card_1_image,
    path: "/fund-houses",
  },
  {
    kind: <Trans>For Distributors</Trans>,
    title: (
      <Trans>
        Unlock all your open architecture needs in a single access point
      </Trans>
    ),
    // description: (
    //   <Trans>
    //     Unlock all your open architecture needs in a single access point
    //   </Trans>
    // ),
    image: home_card_2_image,
    path: "/distributors",
  },
  {
    kind: <Trans>Investment Solutions</Trans>,
    title: <Trans>A robust outsourcing partner</Trans>,
    description: (
      <Trans>
        A range of investment and outsourcing solutions for banks, wealth
        managers and institutional investors.
      </Trans>
    ),
    image: home_card_3_image,
    path: "/fund-houses/investment",
  },
  {
    kind: <Trans>Allfunds Blockchain</Trans>,
    title: <Trans>Revolutionary technology</Trans>,
    description: (
      <Trans>
        Allfunds Blockchain has built a holistic and trailblazing blockchain platform to technologically transform the investment fund environment.
      </Trans>
    ),
    image: home_card_4_image,
    path: "/distributors/blockchain",
  },
];

function HomePage() {
  const { locale } = useLocalization();
  const {
    attributes: {
      title, description, link, image,
    },
  } = homeHero[locale];
  const carouselItems = Object.values(blog[locale])?.splice(0, 6);
  return (
    <NewLayout>
      <Hero
        homePage
        title={title}
        description={description}
        buttonText={<Trans>Who we are</Trans>}
        buttonPath="/about"
        videoPath={link}
        backgroundImage={image?.data?.attributes?.formats?.large?.url || image?.data?.attributes?.url}
      />
      <Container mt={9}>
        <Grid container spacing={2} mt={-7} sx={{ position: "relative" }}>
          {contentImages()
            .splice(0, 2)
            .map((content) => (
              <Grid item xs={12} md={6}>
                <ImageCard
                  image={content.image}
                  header={content.kind}
                  title={content.title}
                  description={content.description}
                  linkPath={content.path}
                  height="650px"
                />
              </Grid>
            ))}
        </Grid>
      </Container>
      <Box sx={{ mt: { xs: 5, md: 10 } }}>
        <Container>
          <QuarterlyFigures />
        </Container>
      </Box>
      <Box sx={{ zIndex: 1, position: "relative" }}>
        <Container>
          <Grid mt={4} container spacing={2} pb={2} sx={{ marginBottom: { xs: "-70rem", sm: "-80rem", md: "-38rem" } }}>
            {contentImages()
              .splice(2)
              .map((content) => (
                <Grid item xs={12} md={6}>
                  <ImageCard
                    image={content.image}
                    header={content.kind}
                    title={content.title}
                    description={content.description}
                    linkPath={content.path}
                    height="650px"
                  />
                </Grid>
              ))}
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          mt: { xs: "22rem", sm: 0 },
          pt: { xs: 0, sm: 10 },
        }}
        pb={6}
      >
        <Container>
          <Box sx={{ paddingTop: { xs: "50rem", sm: "75rem", md: "33rem" } }}>
            <Divider sx={{ background: "white" }} flexItem />
            <Box pt={5}>
              <Carousel
                items={carouselItems}
                title={<Trans>Latest insights</Trans>}
                text={<Trans>The latest news, trends and insights from the world's leading WealthTech company.</Trans>}
                path="/blog"
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </NewLayout>
  );
}
export default HomePage;
